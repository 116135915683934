import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import { feature } from 'topojson-client';
import worldAtlas from 'world-atlas/countries-50m.json';
import { Info } from 'lucide-react'

const countryNameMapping = {
  'United States': 'United States of America',
  'Russia': 'Russian Federation',
  'Iran': 'Iran (Islamic Republic of)',
  'The Netherlands' : 'Netherlands',
  'South Korea': 'Korea, Republic of',
  // Add more mappings as needed
};

const Tooltip = ({ content, position }) => {
  if (!content) return null;

  const tooltipStyle = {
    position: 'fixed',
    left: position.x + 10, // 10px to the right of the cursor
    top: position.y,
    pointerEvents: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    color: '#1a202c',
    padding: '5px 10px',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    whiteSpace: 'nowrap',
    zIndex: 9999,
    fontSize: '12px',
  };

  return ReactDOM.createPortal(
    <div style={tooltipStyle}>{content}</div>,
    document.body
  );
};

const WorldMap = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState('Map'); // 'Map' or 'List'
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Process geographies for the map
  const geographies = useMemo(() => {
    try {
      return feature(worldAtlas, worldAtlas.objects.countries).features;
    } catch (error) {
      console.error('Error processing world atlas data:', error);
      return [];
    }
  }, []);

  // Find the maximum number of calls to calculate percentages
  const maxCalls = useMemo(() => {
    const values = Object.values(data).filter((value) => typeof value === 'number');
    return Math.max(...values, 0);
  }, [data]);

  // Color scale for the map
  const colorScale = useMemo(
    () => scaleLinear().domain([0, maxCalls]).range(['#ff7475', '#f90d0d']),
    [maxCalls]
  );

  // Prepare sorted data for the list
  const sortedData = useMemo(() => {
    return Object.entries(data)
      .sort(([, a], [, b]) => b - a)
      .map(([country, calls]) => ({ country, calls }));
  }, [data]);

  if (geographies.length === 0) {
    return <div className="text-red-500">Error loading map data. Please try again later.</div>;
  }

  return (
    <div className="rounded-lg border bg-white shadow-sm mt-8">
      <h2 className="text-2xl font-bold text-gray-800 p-6">API Calls by Country</h2>

      {/* Tabs */}
      <div className="flex justify-center mb-4 space-x-4">
        <button
          className={`px-4 py-2 font-semibold focus:outline-none ${
            selectedTab === 'Map'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-600 hover:text-blue-600'
          }`}
          onClick={() => setSelectedTab('Map')}
        >
          Map
        </button>
        <button
          className={`px-4 py-2 font-semibold focus:outline-none ${
            selectedTab === 'List'
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-600 hover:text-blue-600'
          }`}
          onClick={() => setSelectedTab('List')}
        >
          List
        </button>
      </div>

      {selectedTab === 'Map' && (
        <div className="relative">
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              scale: 125,
            }}
            width={800}
            height={450}
            style={{ width: '100%', height: 'auto' }}
          >
            <ZoomableGroup
              zoom={1}
              minZoom={1}
              maxZoom={8}
              // Optional: Center the map initially
              center={[0, 35]}
            >
              <Geographies geography={geographies}>
                {({ geographies }) =>
                  geographies
                    .filter((geo) => geo.properties.name !== 'Antarctica')
                    .map((geo) => {
                      const countryName = geo.properties.name;
                      const dataCountryName = Object.keys(data).find(
                        (key) => countryNameMapping[key] === countryName || key === countryName
                      );
                      const calls = data[dataCountryName] || 0;

                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={calls > 0 ? colorScale(calls) : '#f5f3f3'}
                          stroke="#94a3b8"
                          strokeWidth={0.5}
                          style={{
                            default: { outline: 'none' },
                            hover: { outline: 'none', fill: '#f59e0b' },
                            pressed: { outline: 'none' },
                          }}
                          onMouseEnter={(event) => {
                            const callText = calls === 1 ? 'call' : 'calls';
                            setTooltipContent(`${countryName}: ${calls} ${callText}`);
                            setTooltipPosition({
                              x: event.clientX,
                              y: event.clientY,
                            });
                          }}
                          onMouseMove={(event) => {
                            setTooltipPosition({
                              x: event.clientX,
                              y: event.clientY,
                            });
                          }}
                          onMouseLeave={() => {
                            setTooltipContent('');
                          }}
                        />
                      );
                    })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <Tooltip content={tooltipContent} position={tooltipPosition} />
          <p className="text-xs text-gray-500 mt-2 text-center pb-4 flex items-start justify-center space-x-1 px-4">
            <Info className="h-4 w-4 text-gray-400" />
            <span>Use your mouse wheel or touch gestures to zoom and pan the map for a better view of specific regions.</span>
          </p>
        </div>
      )}

      {selectedTab === 'List' && (
        <div className="p-6">
          {sortedData.map((item, index) => {
            const percentage = (item.calls / maxCalls) * 100;
            const callText = item.calls === 1 ? 'call' : 'calls';

            return (
              <div key={index} className="relative mb-4">
                {/* Background bar */}
                <div
                  className="absolute left-0 top-0 h-full opacity-50 rounded"
                  style={{
                    width: `${percentage}%`,
                    backgroundColor: '#4A90E2',
                  }}
                ></div>
                {/* Content */}
                <div className="relative flex justify-between items-center p-2 bg-transparent rounded">
                  <span className="text-gray-800 flex-1 break-words">{item.country}</span>
                  <span className="text-gray-600 flex-shrink-0">
                    {item.calls} {callText}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default WorldMap;
