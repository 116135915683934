import React, { useState, useEffect, useMemo, useContext } from 'react'
import { format, parseISO, differenceInMinutes, subHours, subDays, subMonths } from 'date-fns'
import { Activity, BarChart2, Clock, Database, Users, Loader, Globe, Calendar } from 'lucide-react'
import Overview from './Overview'
import { AuthContext } from '../../../components/AuthContext.js'
import Svg_transition_line  from '../../../components/Svg_transition_line.js'

const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg overflow-hidden ${className}`}>
    {children}
  </div>
)

const CardHeader = ({ children, className }) => (
  <div className={`px-6 py-4 border-b border-gray-200 ${className}`}>
    {children}
  </div>
)

const CardContent = ({ children, className }) => (
  <div className={`px-6 py-4 ${className}`}>
    {children}
  </div>
)

const Tabs = ({ children, value, onValueChange }) => (
  <div className="border-b border-gray-200">
    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { isSelected: child.props.value === value, onClick: () => onValueChange(child.props.value) })
      )}
    </nav>
  </div>
)

const TabsTrigger = ({ children, value, isSelected, onClick }) => (
  <button
    className={`${
      isSelected
        ? 'border-indigo-500 text-indigo-600'
        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
    onClick={onClick}
    aria-current={isSelected ? 'page' : undefined}
  >
    {children}
  </button>
)

const StatItem = ({ icon: Icon, label, value, className }) => (
  <div className={`flex flex-col items-center p-4 bg-gray-50 rounded-lg ${className}`}>
    <Icon className="h-6 w-6 text-blue-500 mb-2" />
    <span className="text-sm text-gray-500 mb-1">{label}</span>
    <span className="text-lg font-semibold text-gray-800">{value}</span>
  </div>
)

const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center text-sm text-gray-600 mb-2">
    <Icon className="h-4 w-4 text-gray-400 mr-2" />
    <span className="font-medium mr-2">{label}:</span>
    <span>{value}</span>
  </div>
)

const UserCard = ({ user }) => {
  const journeyLengthDisplay = user.journeyLength >= 60
    ? `${(user.journeyLength / 60).toFixed(1)} hours`
    : `${user.journeyLength} minutes`;

  const dataUsageDisplay = user.dataUsage >= 1000000000 
    ? `${(user.dataUsage / 1000000000).toFixed(2)} GB` 
    : `${(user.dataUsage / 1000000).toFixed(2)} MB`;

  return (
    <Card className="mb-4 h-full">
      <CardHeader className="bg-gradient-to-r from-blue-500 to-blue-600">
        <h3 className="text-lg font-medium text-white flex items-center">
          <Globe className="mr-2 h-5 w-5" />
          User IP: {user.userId}
        </h3>
        <p className="text-blue-100 text-sm mt-1">Country: {user.events[0].country}</p>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-3 gap-4 mb-6">
          <StatItem icon={Clock} label="Longest Journey" value={journeyLengthDisplay} />
          <StatItem icon={Activity} label="API Calls" value={user.apiCalls} />
          <StatItem icon={Database} label="Data Usage" value={dataUsageDisplay} />
        </div>
        <div className="bg-gray-50 rounded-lg p-4 mb-4">
          <h4 className="text-sm font-semibold text-gray-700 mb-2">Journey Details</h4>
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            <InfoItem icon={BarChart2} label="Total Events" value={user.events.length} />
            <InfoItem icon={Calendar} label="Total Journeys" value={user.totalJourneys} />
            <InfoItem 
              icon={Clock} 
              label="First Event" 
              value={format(parseISO(user.events[0].timestamp), 'PPpp')} 
            />
            <InfoItem 
              icon={Clock} 
              label="Last Event" 
              value={format(parseISO(user.events[user.events.length - 1].timestamp), 'PPpp')} 
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const GroupedUsers = ({ users, groupMetric }) => {
  const sortedUsers = [...users].sort((a, b) => b[groupMetric] - a[groupMetric])
  const topUsers = sortedUsers.slice(0, 10) // Show top 10 users

  return (
    <div>
      {groupMetric === 'journeyLength' && (
        <p className="text-sm text-gray-600 mb-4">
          Note: A journey is considered to have ended if there's 5 hours or more of inactivity. The longest continuous journey for each user is displayed.
        </p>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {topUsers.map((user) => (
          <UserCard key={user.userId} user={user} />
        ))}
      </div>
    </div>
  )
}


const UserBehavior = ({data}) => {
  const { logout } = useContext(AuthContext)
  const [metrics, setMetrics] = useState(data)
  const [loading, setLoading] = useState(true)
  const [groupMetric, setGroupMetric] = useState('overview')
  const [timeFilter, setTimeFilter] = useState('7d')

  useEffect(() => {
    setMetrics(data)
  }, [data]);

  const processedData = useMemo(() => {
    const groupedByIp = metrics.reduce((acc, event) => {
      if (!acc[event.clientIp]) {
        acc[event.clientIp] = [];
      }
      acc[event.clientIp].push(event);
      return acc;
    }, {});

    return Object.entries(groupedByIp).map(([clientIp, events]) => {
      const sortedEvents = events.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      
      const journeys = sortedEvents.reduce((acc, event, index) => {
        if (index === 0 || differenceInMinutes(parseISO(event.timestamp), parseISO(sortedEvents[index - 1].timestamp)) >= 300) {
          acc.push([]);
        }
        acc[acc.length - 1].push(event);
        return acc;
      }, []);

      const longestJourney = journeys.reduce((longest, journey) => {
        const journeyLength = differenceInMinutes(parseISO(journey[journey.length - 1].timestamp), parseISO(journey[0].timestamp));
        return journeyLength > longest ? journeyLength : longest;
      }, 0);

      const apiCalls = sortedEvents.filter(event => event.route.startsWith('/api')).length;
      const dataUsage = sortedEvents.reduce((sum, event) => sum + event.dataSize, 0);

      return {
        userId: clientIp,
        events: sortedEvents,
        journeyLength: longestJourney,
        apiCalls,
        dataUsage,
        totalJourneys: journeys.length
      };
    });
  }, [metrics, timeFilter]);

  return (
    <div className="bg-white">
      <Svg_transition_line top_color={'white'} bottom_color={'#fef5f0'} />
      <div className="container mx-auto px-4">
        <div className="mb-6 flex justify-center">
          <Tabs value={groupMetric} onValueChange={setGroupMetric}>
            <TabsTrigger value="overview">
              <BarChart2 className="mr-2 h-4 w-4 inline" />
              Overview
            </TabsTrigger>
            <TabsTrigger value="journeyLength">
              <Clock className="mr-2 h-4 w-4 inline" />
              Longest Journey
            </TabsTrigger>
            <TabsTrigger value="apiCalls">
              <Activity className="mr-2 h-4 w-4 inline" />
              Most API Calls
            </TabsTrigger>
            <TabsTrigger value="dataUsage">
              <Database className="mr-2 h-4 w-4 inline" />
              Highest Data Usage
            </TabsTrigger>
          </Tabs>
        </div>


            {groupMetric === 'overview' 
              ? <Overview users={processedData} />
              : <GroupedUsers users={processedData} groupMetric={groupMetric} />
            }


      </div>
    </div>
  )
}

export default UserBehavior