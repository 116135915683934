import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User, BarChart2 } from 'lucide-react';
import { AuthContext } from './components/AuthContext.js'; // Adjust the path as necessary

export default function Layout({ children }) {
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();

  const isActive = (path) => {
    if(path == '/profile' && location.pathname === path) return { background: 'black', color: 'white' }
    return location.pathname === path ? { background: '#ff5051', color: 'white' } : {};
  };

  const header = () => {
    if (location.pathname === '/login' || location.pathname === '/join') return null;

    return (
      <header className="w-full border-b bg-white backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="mx-auto px-4 h-16 flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img src={'/logo_125.png'} className="h-6 w-6"/>
            <span className="ml-2 text-xl font-bold">Expensive Users</span>
          </Link>

          {/* Centered Navigation */}
          <nav className="hidden md:flex space-x-4 justify-center items-center flex-grow">
            {user ? (
              <>
                <Link
                  to="/pageviews"
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100"
                  style={isActive('/pageviews')}
                >
                  <BarChart2 className="h-4 w-4 mr-2" />
                  Page Views
                </Link>
                <Link
                  to="/apicalls"
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100"
                  style={isActive('/apicalls')}
                >
                  <BarChart2 className="h-4 w-4 mr-2" />
                  API Calls
                </Link>
              </>
            ) : (
              <>
                <Link
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100"
                >
                  Features
                </Link>
                <Link
                  className="flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100"
                >
                  Pricing
                </Link>
              </>
            )}
          </nav>

          {/* Right-side Buttons */}
          <div className="flex gap-4">
            {user ? (
              <div className="flex items-center">
                <Link
                  to="/profile"
                  className="px-3 py-1 text-black hover:text-white hover:bg-black rounded-lg cursor-pointer"
                  style={isActive('/profile')}
                >
                 {user.full_name}
                </Link>
              </div>
            ) : (
              <>
                <button
                  className="less_cool_button py-2 px-4 rounded-full relative"
                  onClick={() => window.location.href = '/join'}
                >
                  New here? Join Now
                </button>
                <button
                  className="cool_button py-2 px-6 rounded-full relative"
                  onClick={() => window.location.href = '/login'}
                >
                  Login
                </button>
              </>
            )}
          </div>
        </div>
      </header>
    );
  };

  return (
    <div className="min-h-screen bg-background flex flex-col">
      {header()}
      {children}
    </div>
  );
}
