// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Landing from './Landing';
import API from './pages/API';
import SlowestCalls from './SlowestCalls';
import Profile from './pages/Other/Profile';
import Gdpr from './pages/Other/gdpr-compliance.js';
import Login from './pages/Other/Login.js';
import Signup from './pages/Other/Signup.js';
import ApiTester from './pages/Tools/api-tester.js';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/join" element={<Signup />} />
            <Route path="/gdpr" element={<Gdpr />} />
            <Route path="/tools/api-tester" element={<ApiTester />} />

            {/* Protected Routes */}
            <Route
              path="/apicalls"
              element={
                <ProtectedRoute>
                  <API />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}
