import React from 'react'
import { Lock, Database, UserX, Server, FileText, Eye, Users } from 'lucide-react'

const Section = ({ title, icon, children }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-bold mb-4 flex items-center">
      {React.createElement(icon, { className: "mr-2 h-6 w-6" })}
      {title}
    </h2>
    {children}
  </div>
)

const CompliancePoint = ({ title, description }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
)

export default function GDPRCompliancePage() {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">Our Commitment to GDPR Compliance</h1>
        
        <p className="text-xl text-gray-700 mb-8">
          At Expensive Users, we take data protection and privacy seriously. We've implemented robust measures to ensure our full compliance with the General Data Protection Regulation (GDPR). Here's a detailed explanation of the steps we've taken to protect your data and respect your privacy rights.
        </p>

        <Section title="Data Minimization and Purpose Limitation" icon={Database}>
          <CompliancePoint 
            title="Collecting Only Essential Data"
            description="We adhere strictly to the principle of data minimization. We only collect and process personal data that is absolutely necessary for providing our analytics service. This approach not only complies with GDPR but also reduces the risk of unnecessary data exposure."
          />
          <CompliancePoint 
            title="Clear Purpose Definition"
            description="Every piece of data we collect has a specific, predefined purpose related to our service. We do not use your data for any purpose other than what has been explicitly stated and agreed upon."
          />
        </Section>

        <Section title="Anonymization and Pseudonymization" icon={UserX}>
          <CompliancePoint 
            title="IP Address Hashing"
            description="We do not store IP addresses in their original form. Instead, we use a secure hashing algorithm to create a pseudonymized identifier. This means that even if our database were to be compromised, the original IP addresses could not be derived from the stored data."
          />
          <CompliancePoint 
            title="Random Generated IDs"
            description="For user tracking, we generate random, unique identifiers that are not linked to personal data. These IDs allow us to analyze user behavior patterns without identifying individuals, striking a balance between valuable insights and user privacy."
          />
        </Section>

        <Section title="Data Isolation and Protection" icon={Lock}>
          <CompliancePoint 
            title="Secure Data Storage"
            description="All data is stored in encrypted databases with strict access controls. We use industry-standard encryption methods to ensure that data is protected both at rest and in transit."
          />
          <CompliancePoint 
            title="Regular Security Audits"
            description="We conduct regular security audits and penetration testing to identify and address any potential vulnerabilities in our systems."
          />
        </Section>

        <Section title="No Third-Party Data Sharing" icon={Users}>
          <CompliancePoint 
            title="Data Confinement"
            description="We do not share, sell, or transfer your data to any third parties. Your data remains strictly within our systems and is used solely for the purpose of providing our analytics service to you."
          />
          <CompliancePoint 
            title="Careful Vendor Selection"
            description="In cases where we must use third-party services (e.g., cloud hosting), we carefully select vendors that are also GDPR compliant and have signed Data Processing Agreements with us."
          />
        </Section>

        <Section title="Transparency and User Rights" icon={Eye}>
          <CompliancePoint 
            title="Clear Privacy Policy"
            description="Our privacy policy is written in clear, understandable language, detailing what data we collect, how we use it, and your rights regarding your data."
          />
          <CompliancePoint 
            title="Data Subject Rights"
            description="We have implemented processes to handle data subject requests, including the right to access, rectify, erase, and port personal data. Users can easily submit these requests through our platform or by contacting our Data Protection Officer."
          />
        </Section>

        <Section title="Data Retention and Deletion" icon={FileText}>
          <CompliancePoint 
            title="Limited Retention Period"
            description="We only retain personal data for 12 months. We believe this timeframe provides sufficient opportunity for users to gain valuable insights to improve their app performance and understand user behavior. After 12 months, each event is permanently removed from our database, ensuring that we don't keep data longer than necessary."
          />
          <CompliancePoint 
            title="Secure Data Deletion"
            description="When data is deleted, we use secure deletion methods to ensure it cannot be recovered. This includes overwriting data storage areas and verifying the deletion process."
          />
        </Section>

        {/* Remove this entire section */}

        <div className="mt-12 p-6 bg-blue-50 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Our Commitment to You</h2>
          <p className="text-gray-700">
            At Expensive Users, we believe that robust analytics and strong data protection can coexist. Our commitment to GDPR compliance is not just about following rules—it's about respecting our users' privacy and building trust. We continually strive to improve our data protection measures and maintain the highest standards of privacy in the analytics industry.
          </p>
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-600">
            If you have any questions about our GDPR compliance or your data privacy, please don't hesitate to contact our Data Protection Officer at dpo@expensiveusers.com
          </p>
        </div>
      </div>
    </div>
  )
}