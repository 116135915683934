// MostCommonPaths.js
import React, { useEffect, useState } from 'react';

const MostCommonPaths = ({ users }) => {
  const [topPaths, setTopPaths] = useState([]);
  const [ignoreConsecutiveDuplicates, setIgnoreConsecutiveDuplicates] = useState(true); // Default to true
  const [subRouteLength, setSubRouteLength] = useState(3);
  const [topN, setTopN] = useState(10); // Default to 10
  const [sortCriteria, setSortCriteria] = useState('count'); // New state for sorting
  const [activeTab, setActiveTab] = useState('frequency'); // Tabs: 'frequency', 'users', 'dataSize'

  useEffect(() => {
    const findTopPaths = () => {
      const pathData = {};

      users.forEach((user) => {
        let routes = user.events.map((event) => event.route);
        let dataSizes = user.events.map((event) => event.dataSize || 0);

        if (ignoreConsecutiveDuplicates) {
          const filteredRoutes = [];
          const filteredDataSizes = [];

          for (let i = 0; i < routes.length; i++) {
            if (i === 0 || routes[i] !== routes[i - 1]) {
              filteredRoutes.push(routes[i]);
              filteredDataSizes.push(dataSizes[i]);
            }
          }

          routes = filteredRoutes;
          dataSizes = filteredDataSizes;
        }

        for (let i = 0; i <= routes.length - subRouteLength; i++) {
          const subRoute = routes.slice(i, i + subRouteLength).join(' → ');

          if (!pathData[subRoute]) {
            pathData[subRoute] = {
              count: 0,
              userSet: new Set(),
              dataSize: 0,
            };
          }

          pathData[subRoute].count += 1;
          pathData[subRoute].userSet.add(user.userId);

          // Sum the data sizes of the events in the sub-route
          const subRouteDataSize = dataSizes
            .slice(i, i + subRouteLength)
            .reduce((sum, size) => sum + size, 0);
          pathData[subRoute].dataSize += subRouteDataSize;
        }
      });

      // Build the array from pathData
      const pathArray = Object.entries(pathData)
        .filter(([_, data]) => data.count >= 2)
        .map(([path, data]) => ({
          path,
          count: data.count,
          userCount: data.userSet.size,
          dataSize: data.dataSize,
        }));

      // Sort based on the active sort criteria
      const sortedPaths = pathArray
        .sort((a, b) => {
          if (sortCriteria === 'count') {
            return b.count - a.count;
          } else if (sortCriteria === 'userCount') {
            return b.userCount - a.userCount;
          } else if (sortCriteria === 'dataSize') {
            return b.dataSize - a.dataSize;
          }
          return 0;
        })
        .slice(0, topN);

      setTopPaths(sortedPaths);
    };

    if (users && users.length > 0 && subRouteLength >= 2) {
      findTopPaths();
    } else {
      setTopPaths([]);
    }
  }, [users, ignoreConsecutiveDuplicates, subRouteLength, topN, sortCriteria]);

  const handleCheckboxChange = () => {
    setIgnoreConsecutiveDuplicates(!ignoreConsecutiveDuplicates);
  };

  const handleSubRouteLengthChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 2) {
      setSubRouteLength(value);
    } else {
      setSubRouteLength(2);
    }
  };

  const handleTopNChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setTopN(value);
  };

  const handleTabChange = (criteria) => {
    setSortCriteria(criteria);
    if (criteria === 'count') {
      setActiveTab('frequency');
    } else if (criteria === 'userCount') {
      setActiveTab('users');
    } else if (criteria === 'dataSize') {
      setActiveTab('dataSize');
    }
  };

  const renderStyledPath = (path) => {
    const parts = path.split(' → ');
    return parts.reduce((acc, route, index) => {
      acc.push(
        <span key={`route-${index}`} className="">
          {route}
        </span>
      );
      if (index < parts.length - 1) {
        acc.push(
          <span key={`arrow-${index}`} className="mx-1 text-gray-500">
            →
          </span>
        );
      }
      return acc;
    }, []);
  };

  return (
    <div className="mx-auto">
      <h2 className="text-3xl font-semibold mb-6 text-center">
        Top {topN} Most Common Paths
      </h2>
      <div className="flex flex-wrap justify-center items-center space-x-6 mb-8">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={ignoreConsecutiveDuplicates}
            onChange={handleCheckboxChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <span className="text-gray-700">Ignore consecutive duplicates</span>
        </label>
        <label className="flex items-center space-x-2">
          <span className="text-gray-700">Sub-route length:</span>
          <input
            type="number"
            value={subRouteLength}
            onChange={handleSubRouteLengthChange}
            min="2"
            className="w-20 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </label>
        <label className="flex items-center space-x-2">
          <span className="text-gray-700">Top N paths:</span>
          <input
            type="number"
            value={topN}
            onChange={handleTopNChange}
            min="1"
            className="w-20 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </label>
      </div>
      {/* Tabs for sorting */}
      <div className="flex justify-center mb-4">
        <button
          onClick={() => handleTabChange('count')}
          className={`px-4 py-2 mx-1 rounded-t-lg ${
            activeTab === 'frequency'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Sort by Frequency
        </button>
        <button
          onClick={() => handleTabChange('userCount')}
          className={`px-4 py-2 mx-1 rounded-t-lg ${
            activeTab === 'users'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Sort by Users
        </button>
        <button
          onClick={() => handleTabChange('dataSize')}
          className={`px-4 py-2 mx-1 rounded-t-lg ${
            activeTab === 'dataSize'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
        >
          Sort by Data Size
        </button>
      </div>
      {!topPaths.length && (
        <div className="text-center mt-8 text-gray-600">No paths found.</div>
      )}
      <div className="space-y-4">
        {topPaths.map((item, index) => (
          <div key={index} className="p-4 bg-white shadow-md rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="text-xl font-bold text-red-600">
                  #{index + 1}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold">Frequency:</span> {item.count}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold">Users:</span> {item.userCount}{' '}
                  / {users.length}
                </div>
                <div className="text-gray-700">
                  <span className="font-semibold">Data Size:</span>{' '}
                  {item.dataSize}
                </div>
              </div>
            </div>
            <div className="mt-4 text-gray-800 break-words">
              <span className="font-semibold">Path:</span>{' '}
              {renderStyledPath(item.path)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostCommonPaths;
