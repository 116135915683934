import React from 'react'
import { format, parseISO, formatDistanceToNow } from 'date-fns'
import { Info } from 'lucide-react'
import countryCodes from '../../helpers/countryCodes.json'

const RecentApiCalls = ({ data, formatDataSize }) => {

  const countryToFlag = (countryname) => {
    const countryCode = countryCodes[countryname];
    if (!countryCode) return '';
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Recent API Calls</h2>
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-2 px-4 border-b">Timestamp</th>
              <th className="py-2 px-4 border-b">Route</th>
              <th className="py-2 px-4 border-b">Method</th>
              <th className="py-2 px-4 border-b">Response Time</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Data Size</th>
              <th className="py-2 px-4 border-b">Country</th>
            </tr>
          </thead>
          <tbody>
            {data.slice(0, 5).map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="py-2 px-4 border-b">
                  <div className="flex items-center">
                    <span>{formatDistanceToNow(parseISO(item.timestamp), { addSuffix: true })}</span>
                    <div className="relative ml-2 group">
                      <Info className="h-4 w-4 text-gray-400" />
                      <div className="absolute left-full ml-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                        {format(parseISO(item.timestamp), 'yyyy-MM-dd HH:mm:ss')}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="py-2 px-4 border-b">{item.route}</td>
                <td className="py-2 px-4 border-b">{item.method}</td>
                <td className="py-2 px-4 border-b" style={{ color: item.responsetimeseconds < 0.5 ? '#4caf50' : item.responsetimeseconds < 1 ? '#ffc107' : '#f44336' }}>
                  {item.responsetimeseconds.toFixed(2)}s
                </td>
                <td className="py-2 px-4 border-b">{item.statusCode}</td>
                <td className="py-2 px-4 border-b">{formatDataSize(item.dataSize)}</td>
                <td className="py-2 px-4 border-b">
                  {item.country}
                  <span className="ml-2">{countryToFlag(item.country)}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default RecentApiCalls;