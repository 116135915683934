import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import { format, parseISO, subHours, subDays, subMonths, eachDayOfInterval, eachHourOfInterval, eachMonthOfInterval, formatDistanceToNow, subMinutes } from 'date-fns';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Area } from 'recharts'
import { Info, EyeIcon } from 'lucide-react';
import WorldMap from './WorldMap'
import TopApiPaths from './TopApiPaths'
import RecentApiCalls from './RecentApiCalls'
import LoadingScreen from '../../components/LoadingScreen'

import Behavior from './Behavior'


import { AuthContext } from '../../components/AuthContext.js'

const MetricCard = ({ title, value, subtext, extraValues }) => (
  <div className="rounded-lg border bg-white p-4 shadow-sm">
    <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
    <p className="text-3xl font-bold">{value}</p>
    {subtext && <p className="text-sm text-gray-500">{subtext}</p>}
    {extraValues && (
      <div className="flex justify-between mt-2 text-gray-500 text-sm">
        {extraValues.p95 !== undefined && <div>P95: {extraValues.p95}s</div>}
        {extraValues.p99 !== undefined && <div>P99: {extraValues.p99}s</div>}
        {extraValues.healthyCalls !== undefined && <div>Healthy: {extraValues.healthyCalls}</div>}
        {extraValues.errors !== undefined && <div>Errors: {extraValues.errors}</div>}
      </div>
    )}
  </div>
)

const TimeSeriesChart = ({ data, timeRange }) => {
  const processedData = useMemo(() => {
    const now = new Date();
    let startDate, interval, formatKey;

    switch (timeRange) {
      case '24h':
        startDate = subHours(now, 24);
        interval = eachHourOfInterval({ start: startDate, end: now });
        formatKey = (date) => format(date, 'yyyy-MM-dd HH:00');
        break;
      case '7d':
        startDate = subDays(now, 7);
        interval = eachDayOfInterval({ start: startDate, end: now });
        formatKey = (date) => format(date, 'yyyy-MM-dd');
        break;
      case '30d':
        startDate = subDays(now, 30);
        interval = eachDayOfInterval({ start: startDate, end: now });
        formatKey = (date) => format(date, 'yyyy-MM-dd');
        break;
      case '12m':
        startDate = subMonths(now, 12);
        interval = eachMonthOfInterval({ start: startDate, end: now });
        formatKey = (date) => format(date, 'yyyy-MM');
        break;
      default:
        return [];
    }

    const groupedData = interval.reduce((acc, date) => {
      acc[formatKey(date)] = {
        timestamp: formatKey(date),
        apiCalls: 0,
        avgResponse: 0,
        totalCalls: 0,
        totalDataSize: 0
      };
      return acc;
    }, {});

    data.forEach(item => {
      const itemDate = parseISO(item.timestamp);
      if (itemDate >= startDate && itemDate <= now) {
        const key = formatKey(itemDate);
        if (groupedData[key]) {
          groupedData[key].apiCalls += 1;
          groupedData[key].totalCalls += 1;
          groupedData[key].avgResponse = (groupedData[key].avgResponse * (groupedData[key].totalCalls - 1) + item.responsetimeseconds) / groupedData[key].totalCalls;
          groupedData[key].totalDataSize += item.dataSize;
        }
      }
    });

    return Object.values(groupedData);
  }, [data, timeRange]);

  const formatXAxis = (timestamp) => {
    const date = parseISO(timestamp);
    switch (timeRange) {
      case '24h':
        return format(date, 'HH:mm');
      case '7d':
      case '30d':
        return format(date, 'MMM dd');
      case '12m':
        return format(date, 'MMM yyyy');
      default:
        return timestamp;
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border rounded-lg shadow-lg">
          {payload.map((entry, index) => (
            <p key={index} className="text-sm"  style={{ color: entry.color, fontSize: '16px' }}>
              <span className="font-medium">{entry.name}:</span> {entry.name === 'API Calls' ? entry.value : entry.value.toFixed(2)} {entry.name === 'Avg Response Time' ? 's' : ''}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Analytics Overview</h2>
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={processedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              tickFormatter={formatXAxis}
              stroke="#888888"
              tick={{ fill: '#888888', fontSize: 12 }}
            />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar yAxisId="left" dataKey="apiCalls" fill="#8884d8" name="API Calls" />
            <Area
              yAxisId="right"
              type="monotone"
              dataKey="avgResponse"
              name="Avg Response Time"
              stroke="#82ca9d"
              fill="#82ca9d"
              fillOpacity={0.3}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const TimeFilterTabs = ({ timeFilter, setTimeFilter }) => {
  const tabs = [
    { value: '24h', label: 'Last 24 Hours' },
    { value: '7d', label: 'Last 7 Days' },
    { value: '30d', label: 'Last 30 Days' },
    { value: '12m', label: 'Last 12 Months' },
  ];

  return (
    <div className="flex justify-center mb-12">
      <div className="inline-flex rounded-md shadow-sm" role="group">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            type="button"
            className={`px-4 py-2 text-md font-medium ${
              timeFilter === tab.value
                ? 'text-blue-700 bg-blue-100 hover:bg-blue-200'
                : 'text-gray-900 bg-white hover:bg-gray-100'
            } border border-gray-300 ${
              tab.value === '24h' ? 'rounded-l-lg' : tab.value === '12m' ? 'rounded-r-lg' : ''
            } focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700`}
            onClick={() => setTimeFilter(tab.value)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

const formatDataSize = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

const EnhancedApiMetricsDashboard = () => {
  const { user, setUser, checkAuth, logout } = useContext(AuthContext)
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeFilter, setTimeFilter] = useState('24h');
  const [countryData, setCountryData] = useState({});
  const [apiPathData, setApiPathData] = useState({});
  const [activeUsers, setActiveUsers] = useState(0)

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch('http://localhost:5001/api/data', {
        method: 'GET',
        credentials: 'include', // Include cookies
      })

      if (response.status === 401) {
        return logout()
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
    } catch (e) {
      setError('Failed to fetch data. Please check your internet connection.');
      console.error('Error fetching data:', e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const now = new Date();
    const filteredResult = data.filter(item => {
      const itemDate = parseISO(item.timestamp)
      switch (timeFilter) {
        case '24h':
          return itemDate >= subHours(now, 24);
        case '7d':
          return itemDate >= subDays(now, 7);
        case '30d':
          return itemDate >= subDays(now, 30);
        case '12m':
          return itemDate >= subMonths(now, 12);
        default:
          return true;
      }
    })
    setFilteredData(filteredResult);

    // Update country data
    const countries = filteredResult.reduce((acc, item) => {
      if (item.country) {
        acc[item.country] = (acc[item.country] || 0) + 1;
      }
      return acc;
    }, {});
    setCountryData(countries);

    // Update API path data
    const apiPaths = filteredResult.reduce((acc, item) => {
      acc[item.route] = (acc[item.route] || 0) + 1;
      return acc;
    }, {});
    setApiPathData(apiPaths);
  }, [data, timeFilter]);

  const calculateActiveUsers = useCallback(() => {
    const now = new Date()
    const activeTimeframe = subMinutes(now, 5) // Define active timeframe, e.g., last 5 minutes
    const activeUsersSet = new Set()

    data.forEach(item => {
      const itemDate = parseISO(item.timestamp)
      if (itemDate >= activeTimeframe) {
        activeUsersSet.add(item.userId) // Assuming each API call has a 'userId'
      }
    })

    return activeUsersSet.size
  }, [data])

  useEffect(() => {
    setActiveUsers(calculateActiveUsers())
  }, [calculateActiveUsers])

  const metrics = useMemo(() => {
    if (filteredData.length === 0) return { totalCalls: 0, avgResponseTime: 0, errorRate: 0, totalDataSize: 0, p95: 0, p99: 0, healthyCalls: 0, errors: 0 };

    const totalCalls = filteredData.length;
    const avgResponseTime = filteredData.reduce((sum, item) => sum + item.responsetimeseconds, 0) / totalCalls;
    const errorCalls = filteredData.filter(item => item.statusCode >= 400).length;
    const healthyCalls = totalCalls - errorCalls;
    const errorRate = (errorCalls / totalCalls) * 100;
    const totalDataSize = filteredData.reduce((sum, item) => sum + item.dataSize, 0);

    const sortedResponseTimes = filteredData
      .map(item => item.responsetimeseconds)
      .sort((a, b) => a - b);

    const p95Index = Math.floor(sortedResponseTimes.length * 0.95);
    const p99Index = Math.floor(sortedResponseTimes.length * 0.99);

    const p95 = sortedResponseTimes[p95Index].toFixed(2);
    const p99 = sortedResponseTimes[p99Index].toFixed(2);

    return {
      totalCalls,
      avgResponseTime: avgResponseTime.toFixed(2),
      errorRate: errorRate.toFixed(2),
      totalDataSize: formatDataSize(totalDataSize),
      p95,
      p99,
      healthyCalls,
      errors: errorCalls
    };
  }, [filteredData]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  // console.log(metrics.totalCalls)

  return (
    <>
    <div className="min-h-screen bg-[#fdf5f0] py-8">
      <div className="container mx-auto px-4">
        <TimeFilterTabs timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">

          <MetricCard title="Active Users (Last 5 min)" value={activeUsers} />
          
          <MetricCard 
            title="Total API Calls" 
            value={metrics.totalCalls} 
            extraValues={{ healthyCalls: metrics.healthyCalls, errors: metrics.errors }} 
          />
          <MetricCard
            title="Avg Response Time"
            value={`${metrics.avgResponseTime}s`}
            extraValues={{ p95: metrics.p95, p99: metrics.p99 }}
          />

          <MetricCard title="Total Data Size" value={metrics.totalDataSize} />
        </div>

        <div className="mb-8">
          <TimeSeriesChart data={filteredData} timeRange={timeFilter} />
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full lg:w-1/2 px-3 mb-6">
            <WorldMap data={countryData} />
          </div>
          <div className="w-full lg:w-1/2 px-3 mb-6">
            <TopApiPaths data={apiPathData} allData={filteredData} />
          </div>
        </div>

        <RecentApiCalls 
          data={filteredData}
          formatDataSize={formatDataSize}
        />
      </div>

      
    </div>
      <Behavior data={filteredData}/>
    </>
  );
};

export default EnhancedApiMetricsDashboard;