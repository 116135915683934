import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart2, DollarSign, Users, Zap, Lock, PlayCircle, CheckCircle, Check } from 'lucide-react'
import { AuthContext } from './components/AuthContext';

// Inline Button component
const Button = ({ children, variant = 'default', size = 'default', ...props }) => {
  const baseStyles = 'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background'
  const variantStyles = {
    default: 'bg-primary text-primary-foreground hover:bg-primary/90',
    outline: 'border border-input hover:bg-accent hover:text-accent-foreground'
  }
  const sizeStyles = {
    default: 'h-10 py-2 px-4',
    sm: 'h-9 px-3 rounded-md',
    lg: 'h-11 px-8 rounded-md'
  }
  
  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]}`}
      {...props}
    >
      {children}
    </button>
  )
}

const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M5 12h14M12 5l7 7-7 7"/>
  </svg>
)

export default function LandingPage() {
  const {user} = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate("/apicalls")
    }
  }, [user, navigate])

  return (
    <div className="flex flex-col min-h-screen bg-[#fff2eb]">
      <main className="flex-1">
        <section className="w-full py-12 lg:pt-24 x-4 md:px-6">
          <div className="flex flex-col items-center space-y-4 text-center">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-6xl lg:text-7xl/none">
                Understand Your <span className="text-[#f52c2c]">Most Valuable</span> Users
              </h1>
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl dark:text-gray-400">
                Expensive Users provides powerful insights into your <span className="text-red-500 shadow-sm shadow-red-300">high-value customers</span> at a fraction of the cost of traditional analytics tools.
              </p>
            </div>
            <div className="space-x-4">
              <Button size="lg">
                <PlayCircle className="mr-2 h-4 w-4" />
                Try Demo
              </Button>
            </div>
            <div className="w-full max-w-3xl mt-8">
              <img src="/placeholder.svg" alt="Expensive Users Dashboard Preview" width={1200} height={600} className="rounded-lg shadow-lg" />
            </div>
          </div>
        </section>

        <div className="flex flex-col items-center justify-center relative w-screen mt-4 pb-24 bg-white">
          {/* The line */}
          <div className="absolute top-0 left-0 w-full bg-black" style={{height: "2.4px"}}></div>
          {/* The SVG positioned so its top aligns with the line */}
          <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
            <svg width="600px" height="80px" viewBox="0 0 2129 257" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
                  <g className="small_d3" transform="translate(559.000000, 1858.000000)">
                    <rect className="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="2129" height="257"></rect>
                    <path d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z" className="filling" fill="#fff2eb" fillRule="nonzero"></path>
                    <path d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91" className="border" stroke="#000000" strokeWidth="9" strokeLinecap="round"></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>

<section className="px-8 pb-12 rounded-lg text-center w-full relative flex flex-col items-center justify-center bg-white">
  <div className="max-w-7xl mx-auto px-4 ">
    <div className="flex justify-between items-start mb-8">
      <h1 className="text-4xl md:text-5xl font-bold max-w-md">Trusted by Thousands of Companies for</h1>
      <button className="px-4 py-2 rounded-full border border-gray-300 hover:bg-gray-100 transition-colors">About Us</button>
    </div>

    <div className="grid md:grid-cols-2 gap-6">
      {/* Live App Previews Card */}
      <a className="relative block pb-9 lg:pb-0">
        <div className="bg-[#f3f3f3] h-[385px] sm:h-[288px] w-full overflow-hidden rounded-[32px] scroll-m-32 bg-grey-3 p-6 md:py-8 sm:px-4 md:px-6 lg:p-8 lg:pt-6 lg:px-8 lg:pb-0 z-20 relative border-[1px] sm:flex gap-8 sm:gap-4 md:gap-8 lg:block lg:h-[400px] group inline-block transition-colors hover:bg-white">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-2xl font-bold">Live App Previews</h2>
            <ArrowRight />
          </div>
          <p className="text-gray-600 mb-6">Upload your app and share a live link. Embed your mobile Android emulator & iOS simulator seamlessly on any website.</p>
          <div className="bg-black rounded-lg p-4">
            <div className="flex gap-2 mb-4">
              <div className="w-3 h-3 rounded-full bg-red-500" />
              <div className="w-3 h-3 rounded-full bg-yellow-500" />
              <div className="w-3 h-3 rounded-full bg-green-500" />
            </div>
            <div className="relative aspect-[4/3] bg-gray-900 rounded-md overflow-hidden">
              <img src="/placeholder.svg" alt="App Preview" className="object-cover w-full h-full" />
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-16 h-16 rounded-full bg-black/50 flex items-center justify-center">
                  <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
                    <div className="w-0 h-0 border-t-8 border-t-transparent border-l-12 border-l-black border-b-8 border-b-transparent ml-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 pointer-events-none border border-purple-200 rounded-3xl" />
        </div>

        <div className="z-10 absolute bg-[#d2c6ff] bottom-[-8px] right-[-8px] top-[8px] w-full rounded-[37px] h-[385px] sm:h-[288px] lg:h-[400px]"></div>
      </a>

      {/* Mobile App Support Card */}
      <div className="rounded-3xl bg-white p-8 border shadow-sm relative overflow-hidden">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-2xl font-bold">Mobile App Support</h2>
          <ArrowRight />
        </div>
        <p className="text-gray-600 mb-6">Empower your support team with live mobile app assistance. Keep your apps up-to-date without the hassle of managing physical devices or distributing PDFs.</p>
        <div className="relative bg-white rounded-lg p-6">
          <div className="flex items-center gap-4 mb-4">
            <img src="/doordash-logo.svg" alt="DoorDash logo" className="h-6 object-contain" />
          </div>
          <p className="text-sm text-gray-600 mb-4">With our platform, DoorDash has streamlined their support operations, reducing the burden on their agents.</p>
          <p className="text-5xl font-bold mb-1">1,000s</p>
          <p className="text-sm text-gray-600">of successful support interactions.</p>
        </div>
        <div className="absolute inset-0 pointer-events-none border border-green-200 rounded-3xl" />
      </div>

      {/* Mobile Demos Card */}
      <div className="rounded-3xl bg-white p-8 border shadow-sm relative overflow-hidden">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-2xl font-bold">Mobile Demos</h2>
          <ArrowRight />
        </div>
        <p className="text-gray-600 mb-6">Showcase any version of your app on any device with custom branding. Always keep your demos up-to-date effortlessly.</p>
        <div className="relative aspect-[3/2]">
          <img src="/mobile-demo.svg" alt="Mobile demo interface" className="object-contain w-full h-full" />
        </div>
        <div className="absolute inset-0 pointer-events-none border border-purple-200 rounded-3xl" />
      </div>

      {/* Screenshot Automation Card */}
      <div className="rounded-3xl bg-white p-8 border shadow-sm relative overflow-hidden">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-2xl font-bold">Screenshot Automation</h2>
          <ArrowRight />
        </div>
        <p className="text-gray-600 mb-6">Automatically capture and generate screenshots with every app release and update, ensuring consistent and up-to-date visual documentation.</p>
        <div className="bg-black rounded-lg p-4">
          <div className="flex gap-2 mb-4">
            <div className="w-3 h-3 rounded-full bg-red-500" />
            <div className="w-3 h-3 rounded-full bg-yellow-500" />
            <div className="w-3 h-3 rounded-full bg-green-500" />
          </div>
          <pre className="text-sm font-mono text-green-400">
            <code>{`// Automate screenshot capture
await session.tap({
  element: {
    attributes: {
      // iOS example
      accessibilityIdentifier: 'password_field'
    }
  }
})`}</code>
          </pre>
        </div>
        <div className="absolute inset-0 pointer-events-none border border-yellow-200 rounded-3xl" />
      </div>
    </div>

    {/* Privacy-Focused Card */}
    <div className="mt-6 rounded-3xl bg-white p-8 border shadow-sm relative overflow-hidden">
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <div className="flex items-start gap-4 mb-4">
            <h2 className="text-2xl font-bold">Privacy & Security</h2>
            <ArrowRight />
          </div>
          <p className="text-gray-600">Your privacy is our top priority. We ensure that no personal information is stored, and our platform operates without the need for cookies banners.</p>
        </div>
        <div className="relative aspect-video">
          <img src="/privacy-focus.svg" alt="Privacy Focused Interface" className="object-contain w-full h-full" />
        </div>
      </div>
      <div className="absolute inset-0 pointer-events-none border border-green-200 rounded-3xl" />
    </div>
  </div>
</section>

      </main>

      <footer className="flex flex-col items-center justify-center relative w-screen bg-purple-600">
        {/* The line */}
        <div className="absolute top-0 left-0 w-full bg-black" style={{height: "2.4px"}}></div>
        {/* The SVG positioned so its top aligns with the line */}
        <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
          <svg width="600px" height="80px" viewBox="0 0 2129 257" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
                <g className="small_d3" transform="translate(559.000000, 1858.000000)">
                  <rect className="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="2129" height="257"></rect>
                  <path d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z" className="filling" fill="#f8f6f3" fillRule="nonzero"></path>
                  <path d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91" className="border" stroke="#000000" strokeWidth="9" strokeLinecap="round"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>

        <section className="flex flex-col items-center justify-center mt-20 max-w-5xl w-full p-8 pt-12 rounded-lg">
          {/* Heading */}
          <div className="w-full text-center pb-8">
            <h2
              className="text-5xl font-bold text-white mb-8"
              style={{
                textShadow: `
              -2px -2px 0 #000,
               2px -2px 0 #000,
              -2px  2px 0 #000,
               2px  2px 0 #000
            `,
              }}>
              Pays for itself in furniture cost and time savings!
            </h2>
          </div>

          {/* Pricing Cards Container */}
          <div className="flex flex-wrap justify-center gap-8 w-full">
            {/* First Pricing Card */}
            <div className="max-w-sm">
              <div className="less_cool_button_with_shadow rounded-lg border bg-white text-gray-900 shadow-sm">
                <div className="flex flex-col space-y-1.5 p-6">
                  <h3 className="text-3xl font-semibold leading-none tracking-tight">Unlimited Access to all our Tools and Presets</h3>
                  <p className="text-sm text-gray-500">Including any update of furniture and future features</p>
                </div>
                <div className="p-6 pt-0 grid gap-4">
                  <div className="flex items-baseline justify-center gap-1">
                    <span className="text-4xl font-bold">$6</span>
                    <span className="text-gray-500">usd/month</span>
                  </div>
                  <ul className="grid gap-2 py-4">
                    <li className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-500" />
                      <span>Access to all current products</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-500" />
                      <span>Access to all future products</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-500" />
                      <span>Regular updates and new features</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-green-500" />
                      <span>Time-saving tools and designs</span>
                    </li>
                  </ul>
                </div>
                <div className="flex items-center p-6 pt-0">
                  <button className="w-full inline-flex items-center justify-center cool_button py-2 px-6 rounded-full relative">Join Now!</button>
                </div>
              </div>
            </div>

            {/* Second Pricing Card */}
          </div>
        </section>

        {/* Footer */}
        <div className="text-white w-full mt-20 py-4 px-6 text-center">
          <div className="bg-black text-white w-full rounded-xl px-6 text-center p-4">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between">
              <div className="mb-6 md:mb-0">
                <h4 className="text-xl font-bold mb-2">RoomDesigner</h4>
                <p className="text-sm">Making every space feel like home.</p>
              </div>
              <div className="flex flex-col space-y-2 mb-6 md:mb-0">
                <a href="#" className="text-sm hover:underline">
                  Privacy Policy
                </a>
                <a href="#" className="text-sm hover:underline">
                  Terms of Service
                </a>
                <a href="#" className="text-sm hover:underline">
                  Cookies Policy
                </a>
              </div>
              <div className="flex flex-col space-y-2">
                <p className="text-sm">© 2024 RoomDesigner. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
