const Svg_transition_line = ({top_color, bottom_color}) => (
    <div className="flex flex-col items-center justify-center relative w-screen pt-4 pb-24" style={{background: top_color}}>
          {/* The line */}
          <div className="absolute top-0 left-0 w-full bg-black" style={{height: "2.4px"}}></div>
          {/* The SVG positioned so its top aligns with the line */}
          <div className="absolute top-[-5.7px] left-1/2 transform -translate-x-1/2">
            <svg width="600px" height="80px" viewBox="0 0 2129 257" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="Artboard" transform="translate(-559.000000, -1858.000000)">
                  <g className="small_d3" transform="translate(559.000000, 1858.000000)">
                    <rect className="Rectangle" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="2129" height="257"></rect>
                    <path d="M1938.5,0 L1938.5,10.43 L1938.25,10.91 L1842.25,10.91 C1795.91,10.91 1714.15,54.42 1622.36,83.43 C1511.43,118.48 1394.25,153.9 1280.58,129.08 C1212.83,114.29 1104.75,80.49 1036.58,67.96 C874.75,38.22 709.76,141.9 548.44,109.49 C443.01,88.32 355.08,10.91 248.25,10.91 L190,10.91 L190,0 L1938.5,0 Z" className="filling" fill={bottom_color} fillRule="nonzero"></path>
                    <path d="M190.25,10.91 L248.25,10.91 C355.08,10.91 443.01,88.32 548.41,109.49 C709.73,141.9 874.72,38.22 1036.55,67.96 C1104.76,80.49 1212.83,114.29 1280.55,129.08 C1394.21,153.9 1511.4,118.48 1622.33,83.43 C1714.12,54.43 1795.89,10.91 1842.22,10.91 L1938.22,10.91" className="border" stroke="#000000" strokeWidth="9" strokeLinecap="round"></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
  )
export default Svg_transition_line;