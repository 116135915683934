// src/components/UserBehavior/Overview.js
import React from 'react';
import { Clock, Activity, Database } from 'lucide-react';
import MostCommentPath from './MostCommentPath'

const OverviewCard = ({ title, _case, user, metric, icon: Icon }) => {
  // Format metric based on title
  const formattedMetric = (() => {
    switch (_case) {
      case 1:
        return user.journeyLength >= 60
          ? `${(user.journeyLength / 60).toFixed(1)} hours`
          : `${user.journeyLength} minutes`;
      case 2:
        return metric;
      case 3:
        return user.dataUsage >= 1_000_000_000
          ? `${(user.dataUsage / 1_000_000_000).toFixed(2)} GB`
          : `${(user.dataUsage / 1_000_000).toFixed(2)} MB`;
      default:
        return metric;
    }
  })();

  return (
    <div className="rounded-lg border bg-white p-4 shadow-sm">
      {/* Header */}
      <div className="px-6 pt-2 flex items-center justify-center">
          <Icon className="h-5 w-5" />
      </div>
      <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-center min-h-[80px]">
        <h3 className="text-md font-medium leading-6 text-gray-900 flex items-center">
          <span className="text-center">{title}</span>
        </h3>
      </div>

      {/* Content */}
      <div className="px-6 py-4">
        <p className="text-3xl text-center font-bold">{formattedMetric}</p>
      </div>
    </div>
  );
};

const Overview = ({ users }) => {
  if (!users || users.length === 0) {
    return <p className="text-center text-gray-500">No data available.</p>;
  }

  const topJourney = users.reduce((max, user) => (user.journeyLength > max.journeyLength ? user : max), users[0]);
  const topApiCalls = users.reduce((max, user) => (user.apiCalls > max.apiCalls ? user : max), users[0]);
  const topDataUsage = users.reduce((max, user) => (user.dataUsage > max.dataUsage ? user : max), users[0]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <OverviewCard
          title="Longest Journey by a user without 5 hours break"
          _case={1}
          user={topJourney}
          metric={topJourney.journeyLength}
          icon={Clock}
        />
        <OverviewCard
          title="Most API Calls By One User"
          _case={2}
          user={topApiCalls}
          metric={topApiCalls.apiCalls}
          icon={Activity}
        />
        <OverviewCard
          title="Highest Data Used By One User"
          _case={3}
          user={topDataUsage}
          metric={topDataUsage.dataUsage}
          icon={Database}
        />
      </div>

      <MostCommentPath users={users} />
    </>
  );
};

export default Overview;
