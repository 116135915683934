import React, { useState, useEffect } from 'react';

export default function APITester() {
  const [url, setUrl] = useState('');
  const [method, setMethod] = useState('GET');
  const [body, setBody] = useState('');
  const [result, setResult] = useState(null);
  const [processingTime, setProcessingTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set meta tags for SEO
    document.title = 'API Tester - Test Your API Endpoints';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'A user-friendly tool to test GET and POST API calls, view responses, and measure processing time.');
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = 'A user-friendly tool to test GET and POST API calls, view responses, and measure processing time.';
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResult(null);
    setProcessingTime(null);

    const startTime = performance.now();

    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      if (method === 'POST' && body) {
        options.body = body;
      }

      const response = await fetch(url, options);
      const data = await response.json();

      const endTime = performance.now();
      setProcessingTime(endTime - startTime);
      setResult(data);
    } catch (error) {
      setResult({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const exampleCode = `
// Example usage of fetch API
const url = '${url || 'https://api.example.com/data'}';
const options = {
  method: '${method}',
  headers: {
    'Content-Type': 'application/json',
  },
  ${method === 'POST' ? `body: ${body || "JSON.stringify({ key: 'value' })"}, ` : ''}
};`;

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <main className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">API Tester</h1>
        <p className="text-center text-gray-600 mb-8">
          This tool allows you to test GET and POST API calls. Enter the API URL, select the method,
          and for POST requests, provide a JSON body. The tool will show you the response and how long
          it took to process the request.
        </p>
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Test Your API</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                API URL
              </label>
              <input
                id="url"
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://api.example.com/endpoint"
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <fieldset>
              <legend className="text-sm font-medium text-gray-700">Request Method</legend>
              <div className="mt-2 flex space-x-4">
                <div className="flex items-center">
                  <input
                    id="get"
                    type="radio"
                    value="GET"
                    checked={method === 'GET'}
                    onChange={() => setMethod('GET')}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label htmlFor="get" className="ml-2 block text-sm text-gray-700">
                    GET
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="post"
                    type="radio"
                    value="POST"
                    checked={method === 'POST'}
                    onChange={() => setMethod('POST')}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label htmlFor="post" className="ml-2 block text-sm text-gray-700">
                    POST
                  </label>
                </div>
              </div>
            </fieldset>
            {method === 'POST' && (
              <div>
                <label htmlFor="body" className="block text-sm font-medium text-gray-700">
                  Request Body (JSON)
                </label>
                <textarea
                  id="body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="{'key': 'value'}"
                  rows={4}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 font-mono"
                />
              </div>
            )}
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {isLoading ? 'Testing...' : 'Test API'}
            </button>
          </form>
        </section>
        {processingTime !== null && (
          <section className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Results</h2>
            <p className="text-sm text-gray-600">
              Processing Time: {processingTime.toFixed(2)} ms
            </p>
            {result && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">API Response:</h3>
                <pre className="bg-gray-800 text-white p-4 rounded-md overflow-x-auto">
                  <code>{JSON.stringify(result, null, 2)}</code>
                </pre>
              </div>
            )}
          </section>
        )}
        {processingTime == null &&
          <section className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Example Code</h2>
            <pre className="bg-gray-800 text-white p-4 rounded-md overflow-x-auto">
              <code>{exampleCode}</code>
            </pre>
          </section>
        }
      </main>
    </div>
  );
}