import React from 'react';

const TopApiPaths = ({ data }) => {
  // Sort the data by number of calls in descending order and take the top 10
  const sortedData = Object.entries(data)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 10)
    .map(([path, calls]) => ({ path, calls }));

  // Find the maximum number of calls to calculate percentages
  const maxCalls = sortedData[0]?.calls || 1;

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm mt-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Top 10 Most Called API Paths</h2>
      <div>
        {sortedData.map((item, index) => {
          const percentage = (item.calls / maxCalls) * 100;

          return (
            <div key={index} className="relative mb-4">
              {/* Background bar */}
              <div
                className="absolute left-0 top-0 h-full opacity-50 rounded"
                style={{
                  width: `${percentage}%`,
                  backgroundColor: '#4A90E2', // Replace with your desired hex color
                }}
              ></div>
              {/* Content */}
              <div className="relative flex justify-between items-center p-2 bg-transparent rounded">
                <span className="text-gray-800 flex-1 break-words">{item.path}</span>
                <span className="text-gray-600 flex-shrink-0">{item.calls}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopApiPaths;
