import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const designOptions = [
  { id: 1, name: 'Contemporary Chic', image: '/assets/LT01.png' },
  { id: 2, name: 'Rustic Warmth', image: '/assets/LT02.png' },
  { id: 3, name: 'Minimalist Zen', image: '/assets/VS05.png' },
  { id: 4, name: 'Modern Elegance', image: '/assets/VS02.png' },
];

const SignupPage = () => {
  // State for signup form
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // State for confirmation code form
  const [verificationCode, setVerificationCode] = useState('');

  // State to manage the signup flow
  const [isSignedUp, setIsSignedUp] = useState(false);

  // State for feedback messages
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyingLoading, setIsVerifyingLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  // Resend cooldown state
  const [canResend, setCanResend] = useState(true);
  const [resendTimer, setResendTimer] = useState(0);

  // API base URL
  const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  // Effect to handle resend timer countdown
  useEffect(() => {
    let timerInterval;

    if (resendTimer > 0) {
      timerInterval = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            clearInterval(timerInterval);
            setCanResend(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [resendTimer]);

  // Handle Signup
  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Client-side validation
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          full_name: name,
          email: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsSignedUp(true);
        setSuccess(data.message || 'Signup successful! Please check your email to verify your account.');
      } else {
        setError(data.error || 'An error occurred during signup.');
      }
    } catch (err) {
      console.error('Signup error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Verification
  const handleVerification = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Basic validation
    if (!verificationCode) {
      setError('Please enter the verification code.');
      return;
    }

    setIsVerifyingLoading(true);

    try {
      const response = await fetch(`${API_BASE_URL}/verify-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          code: verificationCode,
        }),
        credentials: 'include', // Include credentials to accept cookies
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Email verified successfully! You are now logged in.');
        // Redirect to dashboard or main page
        setTimeout(() => {
          window.location.href = '/overview'; // Adjust the path as needed
        }, 1000);
      } else {
        setError(data.error || 'Verification failed. Please check your code and try again.');
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsVerifyingLoading(false);
    }
  };

  // Handle Resend Verification Code
  const handleResendCode = async () => {
    setError('');
    setSuccess('');
    setIsResending(true);

    try {
      const response = await fetch(`${API_BASE_URL}/resend-verification-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
        credentials: 'include', // Include credentials to accept cookies if needed
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message || 'A new verification code has been sent to your email.');
        setCanResend(false);
        setResendTimer(600); // 10 minutes in seconds
      } else {
        setError(data.error || 'Failed to resend verification code.');
        if (data.error && data.error.includes('wait')) {
          // Extract the remaining time from the error message
          const match = data.error.match(/wait (\d+) more minute\(s\)/);
          if (match && match[1]) {
            setCanResend(false);
            setResendTimer(match[1] * 60); // Convert minutes to seconds
          }
        }
      }
    } catch (err) {
      console.error('Resend verification code error:', err);
      setError('Failed to connect to the server. Please try again later.');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="bg-[#f9f6f3] min-h-screen flex flex-col items-center justify-center relative">
      {/* Header */}
      <header className="absolute top-0 flex justify-between w-full p-6 z-50">
        <a href="/" className="text-red-600 hover:underline">
          &larr; Back to Homepage
        </a>
        <div className="flex space-x-4">
          <button
            className="less_cool_button_with_shadow py-2 px-6 rounded-full relative"
            onClick={() => window.location.href = '/login'}
          >
            Already a Member? Log In
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center text-center px-4 relative z-10 w-full max-w-md">
        {!isSignedUp ? (
          <>
            <h1 className="text-5xl font-bold text-gray-900 leading-tight mb-4">
              Join Us Today!
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Create your account and start designing your dream space.
            </p>

            {/* Signup Form */}
            <form
              onSubmit={handleSignup}
              className="w-full bg-white p-6 rounded-lg shadow-md space-y-4"
            >
              {/* Name Field */}
              <div className="flex flex-col text-left">
                <label htmlFor="name" className="mb-1 text-gray-700">
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="John Doe"
                />
              </div>

              {/* Email Field */}
              <div className="flex flex-col text-left">
                <label htmlFor="email" className="mb-1 text-gray-700">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="you@example.com"
                />
              </div>

              {/* Password Field */}
              <div className="flex flex-col text-left">
                <label htmlFor="password" className="mb-1 text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="••••••••"
                />
              </div>

              {/* Confirm Password Field */}
              <div className="flex flex-col text-left">
                <label htmlFor="confirmPassword" className="mb-1 text-gray-700">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="••••••••"
                />
              </div>

              {/* Error Message */}
              {error && (
                <div className="text-red-500 text-sm">
                  {error}
                </div>
              )}

              {/* Success Message */}
              {success && (
                <div className="text-green-500 text-sm">
                  {success}
                </div>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={isLoading}
              >
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
          </>
        ) : (
          <>
            <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-4">
              Verify Your Email
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Enter the confirmation code sent to your email.
            </p>

            {/* Verification Form */}
            <form
              onSubmit={handleVerification}
              className="w-full bg-white p-6 rounded-lg shadow-md space-y-4"
            >
              {/* Email Field (Hidden) */}
              <input
                type="email"
                id="email"
                required
                value={email}
                readOnly
                className="hidden"
              />

              {/* Verification Code Field */}
              <div className="flex flex-col text-left">
                <label htmlFor="verificationCode" className="mb-1 text-gray-700">
                  Confirmation Code
                </label>
                <input
                  type="text"
                  id="verificationCode"
                  required
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-600"
                  placeholder="Enter your code"
                />
              </div>

              {/* Error Message */}
              {error && (
                <div className="text-red-500 text-sm">
                  {error}
                </div>
              )}

              {/* Success Message */}
              {success && (
                <div className="text-green-500 text-sm">
                  {success}
                </div>
              )}

              {/* Submit Button */}
              <button
                type="submit"
                className={`w-full cool_button py-2 px-4 rounded-full mt-4 ${isVerifyingLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={isVerifyingLoading}
              >
                {isVerifyingLoading ? 'Verifying...' : 'Verify Email'}
              </button>
            </form>

            {/* Resend Code Button */}
            <div className="mt-4">
              <button
                onClick={handleResendCode}
                className={`cool_button py-2 px-4 rounded-full ${!canResend ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-700'
                  }`}
                disabled={!canResend || isResending}
              >
                {isResending ? 'Resending...' : canResend ? 'Resend Code' : `Resend in ${Math.floor(resendTimer / 60)}m ${resendTimer % 60}s`}
              </button>
            </div>
          </>
        )}

        <p className="text-sm text-gray-600 mt-4">
          By signing up, you agree to our{' '}
          <a href="/terms" className="text-red-600 hover:underline">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy" className="text-red-600 hover:underline">
            Privacy Policy
          </a>
          .
        </p>
      </main>

      {/* Design Options - Optional */}
      <div className="w-[80vw] hidden md:flex z-0">
        {designOptions.map((item, index) => (
          <motion.div
            key={item.id}
            className="absolute w-60 h-auto"
            style={{
              top: `${10 + index * 18}%`,
              left: `${index % 2 === 0 ? '5%' : '80%'}`,
              transform: 'translate(-50%, -50%)',
              opacity: 0.9,
              zIndex: 0,
            }}
            animate={{ y: [0, -15, 0] }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              delay: index * 0.5,
            }}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-full object-cover rounded-md"
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SignupPage;
